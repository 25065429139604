/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import Container from '../../components/Container';
import CallToAction from '../../components/CallToAction';
import {
  BodyTextBig,
  SectionTitle,
  WhiteSection,
} from '../../components/Global';

const StartTrialSection = ({
  CtaLink,
  CtaText,
}) => (
  <WhiteSection className="centered-text">
    <Container>
      <SectionTitle mb="16px">
        Try the full power of Workast
      </SectionTitle>
      <BodyTextBig maxWidth="620px" mb="35px">
        No credit cards, no commitments. Start your trial today and take
        your productivity to the next level
      </BodyTextBig>
      <CallToAction
        text={CtaText}
        link={CtaLink}
      />
    </Container>
  </WhiteSection>
);

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
StartTrialSection.propTypes = {
  CtaLink: PropTypes.string.isRequired,
  CtaText: PropTypes.string.isRequired,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default StartTrialSection;
